.root {
  display: grid;
  gap: 16px;
}

.collections {
  display: grid;
  gap: 2px;
}

.input {
  width: 100%;
  height: 34px;
  padding: 2px 8px;
  background-color: var(--colour-blue-800);
  border: 0;
  border-bottom: 1px solid transparent;
  font-family: 'Oxanium', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: -0.03em;
  color: var(--colour-grey-200);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

.input:focus {
  outline: none;
  border-bottom: 1px solid var(--colour-grey-400);
}

.input::placeholder {
  color: var(--colour-grey-400);
}

.new {
  display: grid;
  grid-template-columns: 1fr 80px;
  gap: 2px;
}
