.root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2px;
  background-color: var(--colour-blue-900);
}

.combobox {
  position: relative;
}

.input {
  width: 100%;
  height: 34px;
  padding: 2px 8px;
  background-color: var(--colour-blue-800);
  border: 0;
  border-bottom: 1px solid transparent;
  font-family: 'Oxanium', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 200;
  letter-spacing: -0.03em;
  color: var(--colour-grey-100);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

.input:focus {
  outline: none;
  border-bottom: 1px solid var(--colour-grey-400);
}

.input::placeholder {
  color: var(--colour-grey-400);
}

.list {
  z-index: 1; /* Float above content. */
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 112px;
  box-shadow: 0 3px 5px -2px rgba(0, 0, 0, 0.8);
  background: #0e1013;
  overflow-y: auto;
  cursor: pointer;
}

.item {
  padding: 2px 5px;
}

.highlighted {
  composes: item;
  background-color: var(--colour-blue-800);
}

.count {
  float: right;
}
