.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 2.125em;
  margin: 0;
  padding: 2px 8px;
  border: none;
  background: transparent;
  background-color: var(--colour-blue-800);
  font-family: 'Oxanium', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: var(--colour-grey-400);
  cursor: pointer;
  outline: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

.danger {
  composes: root;
  background-color: var(--colour-red-600);
  color: var(--colour-grey-200);
}

.left {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}

.root:disabled {
  cursor: initial;
}

.root:not(:disabled):hover {
  color: var(--colour-grey-200);
}

.navigation:hover {
  color: var(--colour-grey-100);
}

.icon {
  height: 1em;
  fill: var(--colour-grey-400);
}

.danger .icon {
  fill: var(--colour-grey-200);
}

.iconLeft {
  composes: icon;
  margin-right: 0.4em;
}

.iconRight {
  composes: icon;
  margin-left: 0.4em;
}

.root:not(:disabled):hover .icon {
  fill: var(--colour-grey-200);
}

.danger:not(:disabled):hover {
  background-color: var(--colour-red-500);
  color: #fff;
}

.danger:not(:disabled):hover .icon {
  fill: var(--colour-grey-100);
}

.text {
  font-size: 0.8em;
}
