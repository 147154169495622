.fixedBar {
  z-index: 1; /* Float above content. */
  position: fixed;
  width: 100%;
  background-color: var(--colour-blue-910);
  box-shadow: 0 0 8px var(--colour-blue-920);
}

.navigation {
  composes: fixedBar;
  top: 0;
  height: var(--size-navigation-bar);
}

.screens {
  padding-top: calc(var(--size-navigation-bar) + 16px);
  padding-bottom: calc(var(--size-player-bar) + 26px);
}

.player {
  composes: fixedBar;
  bottom: 0;
  height: calc(var(--size-player-bar));
}
