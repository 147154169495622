.root {
  display: grid;
  gap: 2px;
  align-self: flex-start;
}

.comment {
  padding: 6px 8px;
  background-color: var(--colour-blue-800);
}

.heading {
  padding: 0 8px;
  font-size: 0.9rem;
  line-height: 1.5;
  font-weight: 500;
  text-transform: uppercase;
}
