.root {
  display: grid;
  gap: 2px;
  max-width: 360px;
  margin: 0 auto;
  padding-bottom: 16px;
}

.input {
  width: 100%;
  height: 34px;
  padding: 2px 8px;
  background-color: var(--colour-blue-800);
  border: 0;
  border-bottom: 1px solid transparent;
  font-family: 'Oxanium', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 200;
  letter-spacing: -0.03em;
  color: var(--colour-grey-100);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

.input:focus {
  outline: none;
  border-bottom: 1px solid var(--colour-grey-400);
}

.input::placeholder {
  color: var(--colour-grey-400);
}

.statusText {
  min-height: 34px;
  padding: 6px 8px;
  font-size: 0.9rem;
  line-height: 1.5;
  font-weight: 500;
  text-align: center;
  background-color: var(--colour-blue-800);
}

.submit {
  padding-top: 16px;
}

.link {
  display: block;
  font-size: 0.8rem;
  line-height: 2;
  font-weight: 500;
  color: var(--colour-grey-400);
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
}

.link:hover {
  color: var(--colour-grey-100);
}

.link:active {
  color: var(--colour-grey-200);
}
