.root {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 2.125em;
  margin: 0;
  padding: 2px 8px;
  border: none;
  background: transparent;
  background-color: var(--colour-blue-800);
  font-family: 'Oxanium', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  text-align: left;
  color: var(--colour-grey-400);
  cursor: pointer;
  outline: none;
  overflow: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

.root:disabled {
  cursor: initial;
}

.root:not(:disabled):hover {
  color: var(--colour-grey-200);
}

.collection {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}

.icon {
  width: 24px;
  text-align: right;
}

.icon svg {
  height: 1em;
  fill: var(--colour-grey-400);
  vertical-align: text-bottom;
}

.name {
  font-size: 0.8em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.count {
  padding-left: 0.75ch;
}
