.root {
  display: grid;
  grid-template-rows: minmax(0, min-content);
  grid-template-columns: 1fr;
  gap: 30px;
}

@media (min-width: 650px) and (max-width: 1280px) {
  .root {
    grid-template-columns: 280px 1fr;
  }

  .root > aside:first-of-type {
    grid-row: span 2;
  }
}

@media (min-width: 1281px) {
  .root {
    grid-template-columns: 280px 1fr 1fr;
  }
}
