.root {
  position: relative;
  width: 54px;
  height: 54px;
}

.button {
  composes: button from '../Player/Player.module.css';
}

.icon {
  composes: icon from '../Player/Player.module.css';
}

.control {
  position: absolute;
  left: 17px;
  bottom: 100%;
  width: 54px;
  height: 100px;
  padding: 16px 0 8px;
  background-color: var(--colour-blue-910);
  box-shadow: 0 0 8px var(--colour-blue-910);
  transform: skewX(-20deg);
}

.slider {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  cursor: pointer;
}

.track {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  background-color: var(--colour-blue-700);
}

.volume {
  width: 100%;
  margin: 0 auto;
  background-color: var(--colour-blue-400);
}
