.root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2px;
  width: 100%;
  background-color: var(--colour-blue-900);
  color: var(--colour-grey-200);
}

.drawer {
  composes: root;
  max-width: 386px;
  margin-left: auto;
}

.poster {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-color: var(--colour-blue-800);
  overflow: hidden;
}

.poster img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity var(--duration-short) var(--timing-function-ease-out-cubic);
}

.poster img:first-of-type {
  filter: blur(6px);
}

.posterLoading,
.posterRevealed {
  composes: poster;
}

.posterLoading img:last-of-type {
  opacity: 0;
}

.posterRevealed img:last-of-type {
  opacity: 1;
}

.controls {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
}

.link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 2.125em;
  padding: 2px 8px;
  background-color: var(--colour-blue-800);
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--colour-grey-400);
  cursor: pointer;
  outline: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

.link:hover {
  color: var(--colour-grey-200);
}

.voting {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: var(--colour-grey-400);
}

.score {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.score:not(:first-child) {
  margin: 2px 0;
}

.splitControls {
  display: grid;
  gap: 2px;
}

.spanControls {
  composes: splitControls;
  grid-column: span 2;
  grid-template-columns: 1fr 1fr 1fr;
}

.icon {
  height: 1em;
  margin-right: 0.4em;
  fill: var(--colour-grey-400);
}

.link:hover .icon {
  fill: var(--colour-grey-200);
}

.link .text {
  font-size: 0.8em;
}

.details,
.meta {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 2px 0;
}

.score,
.details div,
.meta div {
  min-height: 34px;
  padding: 2px 8px;
  line-height: 30px;
  background-color: var(--colour-blue-800);
}

.meta small {
  font-size: 0.8em;
  text-transform: uppercase;
}

.details div:nth-of-type(odd),
.meta div:nth-of-type(odd) {
  font-size: 0.8em;
  line-height: 2.5;
  font-weight: 500;
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

.description {
  min-height: 34px;
  padding: 6px 8px;
  line-height: 1.5;
  background-color: var(--colour-blue-800);
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.logo > div {
  position: relative;
  width: 50%;
  height: 30%;
  transform: skewX(-20deg);
}

.logo > div > div {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  background-color: var(--colour-grey-400);
  box-shadow: 3px 5px 6px var(--colour-blue-900);
}

.logo > div > div:first-of-type {
  left: 0;
}

.logo > div > div:last-of-type {
  right: 0;
}
