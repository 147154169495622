.root {
  composes: root from './Link.module.css';
  display: grid;
  grid-template-columns: 44px 1fr;
  gap: 5px;
  align-items: center;
  height: 46px;
  margin-right: 20px;
}

.column {
  position: relative;
}

.text {
  font-weight: 500;
  line-height: 1.2;
  vertical-align: text-top;
}

.progressBar {
  position: absolute;
  left: 0;
  bottom: 7px;
  width: 100%;
}
