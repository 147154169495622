.root {
  width: 43.6470464px;
  padding: 0 5.8235232px;
}

.skew {
  position: relative;
  width: 32px;
  height: 18px;
  transform: skewX(-20deg);
}

.indicator {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--colour-grey-100);
  transition-property: transform, width, height;
  transition-duration: 75ms;
  transition-timing-function: linear;
  will-change: transform, width, height;
}

.cube {
  width: 12.8px;
  height: 5.8px;
}

.tall {
  width: 12.8px;
  height: 18px;
}

.wide {
  width: 32px;
  height: 5.8px;
}

.leftShrinkVertically {
  composes: indicator cube;
  transform: translate(0px, 0px);
}

.rightShrinkVertically {
  composes: indicator cube;
  transform: translate(19.2px, 12.2px);
}

.topExpandHorizontally {
  composes: indicator wide;
  transform: translate(0px, 0px);
}

.bottomExpandHorizontally {
  composes: indicator wide;
  transform: translate(0px, 12.2px);
}

.topShrinkHorizontally {
  composes: indicator cube;
  transform: translate(19.2px, 0px);
}

.bottomShrinkHorizontally {
  composes: indicator cube;
  transform: translate(0px, 12.2px);
}

.leftExpandVertically {
  composes: indicator tall;
  transform: translate(0px, 0px);
}

.rightExpandVertically {
  composes: indicator tall;
  transform: translate(19.2px, 0px);
}
