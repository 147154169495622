.root {
  display: grid;
  grid-template-columns: 54px 1fr 54px;
  gap: 8px;
  justify-items: center;
  padding: 8px 0;
}

.details {
  display: none;
}

.title {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1;
}

.artists {
  font-size: 0.9rem;
  line-height: 1;
}

.labels {
  font-size: 0.75rem;
  line-height: 1;
  color: var(--colour-grey-400);
}

.controls {
  display: grid;
  grid-template-columns: min-content min-content min-content;
  gap: 8px;
}

.volume {
  display: none;
}

.scrubber {
  position: absolute;
  top: -13px;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 24px;
  padding-right: 10px;
  transition: opacity var(--duration-medium) var(--timing-function-ease-out-cubic);
  opacity: 1;
  cursor: pointer;
}

.noScrubber {
  composes: scrubber;
  z-index: -1;
  transition-timing-function: var(--timing-function-ease-in-sine);
  opacity: 0;
  cursor: default;
}

.progress {
  z-index: -1; /* Prevent being a target for drag event listeners */
  position: relative;
  height: 2px;
  background-color: var(--colour-blue-400);
  border-left: 11px solid var(--colour-blue-400);
}

.progress::after {
  position: absolute;
  top: -7px;
  right: -6px;
  content: '';
  width: 11px;
  height: 16px;
  background-color: var(--colour-blue-400);
  transform: skewX(-20deg);
  box-shadow: 0 0 5px var(--colour-blue-600);
}

.poster {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  outline: none;
  cursor: pointer;
}

.poster[disabled] {
  cursor: default;
}

.posterRevealed {
  composes: posterRevealed from '../MixDetails/MixDetails.module.css';
}

.posterLoading {
  composes: posterLoading from '../MixDetails/MixDetails.module.css';
}

.logo {
  composes: logo from '../MixDetails/MixDetails.module.css';
}

.button {
  width: 54px;
  height: 54px;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  font-size: 1.0625rem;
  line-height: 1;
  fill: var(--colour-grey-200);
  cursor: pointer;
  outline: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

.button:hover {
  fill: var(--colour-grey-100);
}

.icon {
  height: 1.5rem;
  line-height: 54px;
}

@media (min-width: 650px) {
  .root {
    grid-template-columns: 54px 1fr 240px 54px;
  }

  .details {
    display: inherit;
    justify-self: flex-start;
    align-self: center;
    width: 100%;
    height: 54px;
  }

  .details > div {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .controls {
    grid-template-columns: min-content min-content min-content min-content;
  }

  .volume {
    display: inherit;
  }
}
