.root {
  max-width: 1480px;
  margin: 0 auto;
  padding: 0 10px;
}

@media (min-width: 426px) {
  .root {
    padding: 0 30px;
  }
}
