.root {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 1420px;
  height: var(--size-navigation-bar);
  margin: 0 auto;
  padding: 0;
  border: none;
  background: transparent;
  background-color: var(--colour-blue-910);
  font-family: 'Oxanium', sans-serif;
  font-size: 1.0625rem;
  line-height: 1;
  font-weight: 500;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: var(--colour-grey-200);
  cursor: pointer;
  outline: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

.left {
  justify-content: flex-start;
  padding-left: 6px;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
  padding-right: 6px;
}

.root:hover {
  color: var(--colour-grey-100);
}

.icon {
  height: 1.25em;
  fill: var(--colour-grey-200);
}

.iconLeft {
  composes: icon;
  margin-right: 0.4em;
}

.iconRight {
  composes: icon;
  margin-left: 0.4em;
}

.root:hover .icon {
  fill: var(--colour-grey-100);
}

.text {
  font-size: 0.7647058824em;
}

.spinnerLeft {
  position: absolute;
  left: 0;
}

.spinnerRight {
  position: absolute;
  right: 0;
}
