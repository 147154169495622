.root > :not(:last-child) {
  margin-bottom: 30px;
}

.root > blockquote {
  padding: 6px 8px;
  background-color: var(--colour-blue-800);
  line-height: 1.5;
}

.root > blockquote p:not(:last-of-type) {
  margin-bottom: 0.75em;
}

.root ul,
.root ol {
  display: grid;
  gap: 2px;
  margin: 0 0 0 32px;
  padding: 0;
  list-style-position: outside;
}

.root ul > li,
.root ol > li {
  position: relative;
  min-height: 34px;
  padding: 6px 8px;
  background-color: var(--colour-blue-800);
  line-height: 1.5;
}

.root ul > li::before,
.root ol > li::before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 0;
  left: -32px;
  display: block;
  width: 34px;
  height: 100%;
  background-color: var(--colour-blue-800);
}

.root ol:first-of-type {
  position: relative;
  padding-top: 25px;
}

.root ol:first-of-type::before {
  content: 'Tracklist';
  position: absolute;
  left: -32px;
  padding: 2px 8px;
  text-transform: uppercase;
  font-size: 0.9rem;
  line-height: 1.5;
  font-weight: 500;
}

.root a,
.root a:visited,
.root a:active {
  color: var(--colour-blue-400);
  text-decoration: none;
}

.root a:hover {
  color: var(--colour-grey-100);
}

.root img {
  width: 100%;
}
