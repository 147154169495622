.root {
  composes: root from './Aside.module.css';
  position: fixed;
  top: var(--size-navigation-bar);
  left: 0;
  height: calc(100% - var(--size-navigation-bar));
  padding-bottom: var(--size-player-bar);
  transition: transform var(--duration-medium) var(--timing-function-ease-out-sine);
  will-change: transform;
  z-index: 1;
}

.open {
  composes: root;
  transition-timing-function: var(--timing-function-ease-out-cubic);
  transform: translateX(0);
}

.left {
  composes: root;
  transform: translateX(-100%);
}

.right {
  composes: root;
  transform: translateX(100%);
}

.overflow {
  max-height: 100%;
  padding: 16px 10px;
  overflow-y: auto;
  overscroll-behavior: none;
}

.overflow::after {
  /* Fake padding because flexbox internal padding doesn't work */
  /* https://blog.alexandergottlieb.com/overflow-scroll-and-the-right-padding-problem-a-css-only-solution-6d442915b3f4 */
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  margin-top: -1px;
}

@media (min-width: 426px) {
  .overflow {
    padding: 16px 30px;
  }
}

.overlay {
  position: fixed;
  top: var(--size-navigation-bar);
  left: 0;
  width: 100%;
  height: calc(100% - var(--size-navigation-bar));
  transition: opacity var(--duration-medium) var(--timing-function-ease-out-cubic);
  will-change: opacity;
  z-index: 1;
}

.revealTransparent {
  composes: overlay;
  background-color: var(--colour-blue-900-alpha-50);
  opacity: 1;
}

.revealOpaque {
  composes: overlay;
  background-color: var(--colour-blue-900);
  opacity: 1;
}

.hide {
  composes: overlay;
  opacity: 0;
}

.revealTransparent + .root > .overflow > div {
  box-shadow: 0 0 20px var(--colour-blue-920);
}
