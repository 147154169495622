.root {
  height: var(--size-navigation-bar);
  text-decoration: none;
  white-space: nowrap;
  color: var(--colour-blue-200);
}

.root:hover {
  color: var(--colour-grey-100);
}

.collapsed {
  composes: root;
}

.root.active {
  border-bottom: 3px solid var(--colour-blue-400);
}

.collapsed.active {
  border-bottom: 0;
  border-left: 3px solid var(--colour-blue-400);
}

.text {
  display: inline-block;
  padding: 0 20px;
  line-height: 1.2;
  vertical-align: text-top;
}
