*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

html {
  overflow-y: scroll;

  --colour-blue-400: #19a3ff;
  --colour-blue-500: #196dbb;
  --colour-blue-600: #285681;
  --colour-blue-700: #25354b;
  --colour-blue-800: #242a32;
  --colour-blue-900: #1a1f24;
  --colour-blue-900-alpha-50: #1a1f2480;
  --colour-blue-910: #13161a;
  --colour-blue-920: #0e1013;
  --colour-grey-100: #f1f1f1;
  --colour-grey-200: #cad1d9;
  --colour-grey-400: #8899a9;
  --colour-grey-600: #566776;
  --colour-red-500: #bb1919;
  --colour-red-600: #812828;

  --duration-short: 150ms;
  --duration-medium: 300ms;

  --size-navigation-bar: 46px;
  --size-player-bar: 72px;

  --timing-function-ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
  --timing-function-ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
}

body {
  min-height: 100vh;
  background-color: var(--colour-blue-900);
  color: var(--colour-grey-200);
  font-family: 'Oxanium', sans-serif;
  font-size: 16px;
  font-weight: 200;
  letter-spacing: -0.03em;
}

b,
strong {
  font-weight: 500;
}
