.root {
  width: 100%;
  background-color: var(--colour-blue-920);
  border-left: 4px solid var(--colour-grey-100);
  transition: opacity 500ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
}

.done {
  composes: root;
  opacity: 0;
}

.progressing {
  composes: root;
  opacity: 1;
}

.progressBar {
  width: var(--width);
  height: 2px;
  background-color: var(--colour-grey-100);
  transition: width 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
