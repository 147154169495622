.root {
  display: grid;
  gap: 30px;
}

.oneColumn {
  composes: root;
  grid-template-columns: 1fr;
}

.twoColumns {
  composes: root;
  grid-template-columns: 220px 1fr;
}

.threeColumns {
  composes: root;
  grid-template-columns: 220px 1fr 280px;
}

.search {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  align-content: flex-start;
  background-color: var(--colour-blue-900);
}

.results {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2px;
  align-content: flex-start;
}

.searchButton {
  padding-bottom: 8px;
}

.footer {
  padding-top: 16px;
}

.footer:first-of-type {
  padding-top: 34px;
}
