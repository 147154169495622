.root {
  transition-property: opacity;
  transition-duration: var(--duration);
  transition-timing-function: var(--timing-function-ease-out-cubic);
  will-change: opacity;
  opacity: 1;
}

.transparent {
  composes: root;
  transition-timing-function: var(--timing-function-ease-out-sine);
  opacity: 0;
}
