.root {
  display: grid;
  grid-template-columns: max-content 1fr 46px;
  height: var(--size-navigation-bar);
  font-size: 1.0625rem;
  line-height: 46px;
}

.links {
  display: flex;
  flex-flow: row nowrap;
}

.logo {
  padding: 0 20px;
  text-decoration: none;
  white-space: nowrap;
  color: var(--colour-grey-200);
}

.dropdown {
  position: relative;
  height: var(--size-navigation-bar);
}

.open,
.closed {
  composes: dropdown;
}

.open {
  background-color: var(--colour-blue-920);
}

.button {
  width: 100%;
  height: var(--size-navigation-bar);
  padding: 0 20px;
  background: transparent;
  border: 0;
  font-size: 1.0625rem;
  font-weight: 700;
  color: var(--colour-grey-200);
  cursor: pointer;
}

.button:hover {
  color: var(--colour-grey-100);
}

.list {
  position: absolute;
  right: 0;
  top: 100%;
  display: grid;
  grid-auto-rows: auto;
  max-height: calc(100vh - var(--size-navigation-bar) - var(--size-player-bar) - 10px);
  background-color: var(--colour-blue-920);
  overflow-y: auto;
}

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--size-navigation-bar);
  padding: 0 10px;
  background-color: var(--colour-blue-910);
  border-bottom: 3px solid var(--colour-blue-910);
  transition: opacity var(--duration-medium) var(--timing-function-ease-out-sine);
}

@media (min-width: 426px) {
  .sidebar {
    padding: 0 30px;
  }
}

.hide {
  composes: sidebar;
  opacity: 0;
}

.reveal {
  composes: sidebar;
  transition-timing-function: var(--timing-function-ease-out-cubic);
  opacity: 1;
}
